import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Grid, GridCell, H2 } from '../../components';
import { ConnectionPriceMatrixes } from '../../components/ConnectionPriceMatrixes';
import { useCurrentUser } from '../../lib/core/useCurrentUser';
import Product from './Product';
import { useQuery } from '@apollo/client';
import {
  buyProductPageQuery,
  buyProductPageQueryVariables
} from '../../core-types';
import {
  BUY_PRODUCT_PAGE_QUERY,
  BUY_PRODUCT_PAGE_VARIABLES
} from '../../lib/core/queries';
import { messages } from './messages';

const Products = ({ listName }: { listName: string | null }) => {
  const { currentUser } = useCurrentUser();
  const elRef = useRef<HTMLDivElement | null>(null);
  const { data } = useQuery<buyProductPageQuery, buyProductPageQueryVariables>(
    BUY_PRODUCT_PAGE_QUERY,
    {
      variables: BUY_PRODUCT_PAGE_VARIABLES
    }
  );
  const systemTariffs =
    (data && data.system && data.system.connectionPriceMatrixes) || [];

  const systemId = data?.system?.id;

  const currentSubscriptionProductId =
    currentUser &&
    currentUser.currentSubscription &&
    currentUser.currentSubscription.product &&
    currentUser.currentSubscription.product.id;

  if (!data || !data.products) {
    return null;
  }

  //Temporary hack for hiding products with the names that starts with Giftcard or Gavekort
  //Bad solution, I know. The situation required quick fix.
  const filteredProducts = data.products.filter(
    product =>
      !(
        product.name?.startsWith('Gavekort') ||
        product.name?.startsWith('Giftcard')
      )
  );

  return (
    // Grid doesnt support ref
    <div ref={elRef}>
      <Grid data-testid="ProductPlans__Product" justifyContent="center">
        {filteredProducts.map(product => {
          return (
            <GridCell
              key={product.id}
              width={[1, 1, 1, 1 / 2, 1 / 2, 1 / 2]}
              mb="sm"
            >
              <Product
                product={product}
                currentSubscriptionProductId={currentSubscriptionProductId}
                listName={listName}
              />
            </GridCell>
          );
        })}
      </Grid>

      {systemTariffs.length > 0 && (
        <Box mt="sm" mb="lg">
          <H2 color="primaryContrast" typoStyle="md" textAlign="center">
            {systemId === 'inurba-rouen' ? (
              <FormattedMessage {...messages.stepTwo} />
            ) : (
              <FormattedMessage {...messages.tariffs} />
            )}
          </H2>

          <ConnectionPriceMatrixes
            gridWidth={{ _: 1, md: 1 / 2, xl: 1 / 3 }}
            // Temporary FIX before we implement reservation feature in Admin
            //items={systemTariffs}
            items={systemTariffs.filter(
              tariff => tariff.type !== 'reservation'
            )}
          />
        </Box>
      )}
    </div>
  );
};

export default Products;
