import { gql } from '@apollo/client';
import {
  PRODUCT_FRAGMENT,
  CONNECTION_PRICE_MATRIX_FRAGMENT
} from './fragments';
import { SanityImageOptionFit, SanityImageOptionCrop } from '../../core-types';

export const BUY_PRODUCT_PAGE_VARIABLES = {
  productImageOptions: {
    width: 720,
    height: 480,
    fit: SanityImageOptionFit.crop,
    crop: SanityImageOptionCrop.entropy
  }
};

export const BUY_PRODUCT_PAGE_QUERY = gql`
  ${PRODUCT_FRAGMENT}
  ${CONNECTION_PRICE_MATRIX_FRAGMENT}
  query buyProductPageQuery(
    $systemId: ID
    $locale: String
    $productImageOptions: SanityImageOptions
  ) {
    products(systemId: $systemId, locale: $locale, channel: web) {
      ...Product
    }
    buyProductPage(systemId: $systemId, locale: $locale) {
      title
      heading
      giftCardBlock
      purchaseFeatures {
        _key
        title
        description
      }
      consents {
        info
        autoRenew
        allowCharges
      }
    }
    system {
      id
      connectionPriceMatrixes {
        ...ConnectionPriceMatrix
      }
    }
  }
`;
