import { FormattedMessage } from 'react-intl';
import {
  Flex,
  Box,
  H2,
  Text,
  Button,
  Link,
  I18NLogOut,
  ButtonGroup,
  Grid,
  GridCell,
  Hide
} from '../../components';
import { Profile as ProfileIcon } from '@urbaninfrastructure/react-icons';
import { useAuth } from '../../lib/with-auth';
import { useCurrentUser } from '../../lib/core/useCurrentUser';
import { LaunchAppButton } from '../../components/LaunchApp';

export default function CurrentUserSubscription() {
  const { logOut } = useAuth();
  const { currentUser } = useCurrentUser();
  if (!currentUser || !currentUser.currentSubscription) {
    return null;
  }
  return (
    <Box bg="white" boxShadow="heavy" borderRadius="md" p="sm" mb="sm">
      <Grid>
        <GridCell width={[1, 1, 1, 2 / 3]}>
          <H2 mb="xs" typoStyle="md">
            <FormattedMessage
              id="modules.BuyProduct.activeSubscription.title"
              defaultMessage="You already have a subscription"
            />
          </H2>
          <Text as="p" mb="sm">
            <FormattedMessage
              id="modules.BuyProduct.activeSubscription.description"
              defaultMessage="You can only have one active subscription at a time. If you want to buy a subscription with another phone number than {phoneNumber}, you can log out"
              values={{
                phoneNumber: (
                  <Text as="span" whiteSpace="pre">
                    {currentUser.parsedPhoneNumber &&
                      currentUser.parsedPhoneNumber.formattedNumber}
                  </Text>
                )
              }}
            />
          </Text>
          <ButtonGroup>
            <Link route="profile" passHref>
              <Button as="a" shape="cool" variant="primary" mr="sm">
                <FormattedMessage
                  id="modules.BuyProduct.activeSubscription.profile"
                  defaultMessage="Your profile"
                />
              </Button>
            </Link>
            <LaunchAppButton />
            <Button variant="link" onClick={() => logOut()}>
              <I18NLogOut />
            </Button>
          </ButtonGroup>
        </GridCell>
        <GridCell width={[1, 1, 1, 1 / 3]}>
          <Flex
            justifyContent="center"
            alignItems="center"
            column
            height="100%"
          >
            <Hide xs sm md>
              <ProfileIcon size="150px" color="primary" />
            </Hide>
          </Flex>
        </GridCell>
      </Grid>
    </Box>
  );
}
