import React from 'react';
import { Box, Button } from '@urbaninfrastructure/react-ui-kit';
import { useSystem } from '../lib/core/useSystem';
import { FormattedMessage } from 'react-intl';
import { settings_system } from '../core-types';

function launchApp(system: settings_system) {
  const url = `https://app.${system.publicDomain}`;
  window.location.replace(url);
}

export const LaunchAppButton = () => {
  const system = useSystem();

  if (!system.id) {
    return null;
  }

  return (
    <Box mb="xs">
      <Button shape="cool" onClick={() => launchApp(system)}>
        <FormattedMessage id="launch_app" defaultMessage="Launch app" />
      </Button>
    </Box>
  );
};
