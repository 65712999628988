import { settings, settings_system } from '../../core-types';
import { useSettingsQuery } from './SettingsQuery';
import { QueryHookOptions } from '@apollo/client';

// System should always be loaded trough SSR in _app. If its not, something went wrong.
export function useSystem(
  options?: QueryHookOptions<settings>
): settings_system {
  const { data } = useSettingsQuery(options);
  if (!data || !data.system) {
    throw new Error('System not found');
  }
  return data.system;
}
