import { gql } from '@apollo/client';

export const CONNECTION_PRICE_MATRIX_FRAGMENT = gql`
  fragment ConnectionPriceMatrix on ConnectionPriceMatrix {
    id
    type
    priceMatrix {
      lastStepInfinite
      matrix {
        minutes
        price
        quantity
        type
      }
    }
    vehicleCategory
  }
`;

export const PRODUCT_FRAGMENT = gql`
  ${CONNECTION_PRICE_MATRIX_FRAGMENT}

  fragment Product on Product {
    id
    descriptionText
    name
    image(options: $productImageOptions) {
      url
      metadata {
        dimensions {
          width
          height
        }
      }
    }
    slug {
      current
    }
    price
    extendedRentalPeriodCharge
    tripStartCharge
    rentalMeterType
    campaign
    purchasable
    purchasableFrom
    purchasableTo
    connectionPriceMatrixes {
      ...ConnectionPriceMatrix
    }
    renewOptMethod
    durationMethod
    durationMins
  }
`;
