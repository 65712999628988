import { ButtonAnchor, Link, ProductPlanCard } from '../../components';
import { buyProductPageQuery_products } from '../../core-types';

type Props = {
  product: buyProductPageQuery_products;
  currentSubscriptionProductId?: string | null;
  hideBuyButton?: boolean;
  listName: string | null;
};
const ProductPlan = ({
  product,
  currentSubscriptionProductId,
  hideBuyButton,
  listName
}: Props) => {
  const productSlug = (product.slug && product.slug.current) || product.id;

  if (!product.name) {
    return null;
  }

  return (
    <ProductPlanCard
      name={product.name}
      description={product.descriptionText}
      extendedRentalPeriodCharge={product.extendedRentalPeriodCharge}
      tripStartCharge={product.tripStartCharge}
      rentalMeterType={product.rentalMeterType}
      price={product.price}
      image={product.image}
      connectionPriceMatrixes={product.connectionPriceMatrixes || []}
      renderBuyButton={({ buttonProps }) => {
        if (currentSubscriptionProductId || hideBuyButton) {
          return null;
        }
        return (
          <Link
            route="buy"
            params={{
              productSlug,
              listName
            }}
            passHref
          >
            <ButtonAnchor
              {...buttonProps}
              data-testid={`ProductPlans__Product__${product.id}`}
            />
          </Link>
        );
      }}
    />
  );
};

export default ProductPlan;
